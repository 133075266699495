<template>
  <CRow>
    <CCol>
      <CCard>
        <CCardHeader>
          <b>Add Profile</b>
        </CCardHeader>
        <CCardBody>
          <CAlert
            :color="alertColor"
            closeButton
            :show.sync="showAlert"
          >
            {{ alertContent }}
          </CAlert>
          <CTabs>
            <CTab active>
              <template slot="title">
                <CIcon name="cil-calculator"/> {{tabs[0]}}
              </template>
              <CForm style="margin-top: 24px;">
                <CInput
                  label="User Name"
                  horizontal
                  type="text"
                  v-model="userName"
                />
                <!-- <CInput
                  label="User ID"
                  horizontal
                  type="text"
                  v-model="userId"
                /> -->
                <!-- <CInput
                  label="App ID"
                  horizontal
                  type="text"
                  v-model="appId"
                /> -->
                <div class="form-group form-row">
                  <label class="col-sm-3">List ID</label>
                  <div class="col-sm-9">
                    <v-select
                      :options="availableApps"
                      v-model="appId"
                      @input="onAppIdsChanged"
                    />
                  </div>
                </div>
                <!-- <CInput
                  label="Image"
                  horizontal
                  type="text"
                  v-model="image"
                /> -->
                <CInput
                  label="Phone Number"
                  horizontal
                  type="number"
                  v-model="phoneNumber"
                />
                <br>
                <CCard>
                  <CCardHeader>
                    <b>Custom Fields</b>
                    <div class="card-header-actions">
                    <CButton
                      color="primary"
                      @click="addCustomField">
                      +
                    </CButton>
                  </div>
                  </CCardHeader>
                  <CCardBody>
                    <div class="form-group form-row" v-for="fieldItem in customFields" :key="fieldItem.id">
                      <div class="col-sm-3">
                        <input type="text" class="form-control" placeholder="field_key" v-model="fieldItem.key"/>
                      </div>
                      <!-- <div class="col-sm-3">
                        <input type="text" class="form-control" placeholder="Type"/>
                      </div> -->
                      <div class="col-sm-8">
                        <input type="text" class="form-control" placeholder="Value" v-model="fieldItem.value"/>
                      </div>
                      <div class="col-sm-1">
                        <CButton
                          color="danger"
                          @click="removeCustomField(fieldItem)">
                          -
                        </CButton>
                      </div>
                    </div>
                  </CCardBody>
                </CCard>
                
                <br><hr>
                <div class="form-actions">
                  <CButton
                    color="primary"
                    :disabled='submitted'
                    @click="addProfileClicked()">
                    Add Profile
                  </CButton>
                </div>
              </CForm>
            </CTab>
            <CTab>
              <template slot="title">
                <CIcon name="cil-basket"/> {{tabs[1]}}
              </template>
              <CForm style="margin-top: 24px;">
                <CInputFile
                  label="Import File"
                  :placeholder="importPlaceholder"
                  horizontal
                  custom
                  accept=".csv"
                  @change="onFileChanged"
                />
                <br><hr>
                <div class="form-actions">
                  <CButton
                    color="primary"
                    :disabled='submitted'
                    @click="importClicked()">
                    Import Profile
                  </CButton>
                  <CButton
                    style="margin-left: 8px"
                    color="secondary"
                    :href="sampleCsv"
                    target="_blank"
                    >
                    Sample File
                  </CButton>
                </div>
              </CForm>
            </CTab>
          </CTabs>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import AxiosBase from '../../api/AxiosBase'
import AppApi from '../../api/AppApi'

export default {
  name: 'AddProfile',
  created() {
    Vue.component('v-select', vSelect);
    this.getApps();
  },
  data () {
    return {
      tabs: [
        'Add One',
        'Import from File'
      ],
      alertColor: 'success',
      showAlert: false,
      alertContent: "",
      sampleCsv: process.env.VUE_APP_SAMPLE_CSV,

      availableApps: [],
      // selectedApps: [],

      submitted: false,
      userName: "",
      appId: "",
      // image: "",
      phoneNumber: "",

      customFieldId: 0,
      customFields: [],

      importPlaceholder: "Select CSV file...",
      importFile: null,
    }
  },
  methods: {
    getApps() {
      var _this = this;
      AppApi.getApps().then(result => _this.availableApps = result);
    },
    resetForm() {
      this.userName = "";
      this.appId = "";
      // this.image = "";
      this.phoneNumber = "";

      this.importPlaceholder = "Select CSV file...";
      this.importFile = null;

      this.customFieldId = 0;
      this.customFields = [];
    },
    addProfile(formData) {
      var _this = this;
      _this.submitted = true;

      AxiosBase.post("/profile/put", formData)
      .catch(function(error) {
        console.log(error);

        _this.alertColor = "danger";
        _this.showAlert = true;
        _this.alertContent = "Create new profile failed. Check console for detail."
        _this.submitted = false;
      })
      .then(function(res) {
        var resCode = res?.data?.code;
        var newId = res?.data?.value;

        if (resCode != null && resCode >= 0) {
          _this.alertColor = "success";
          _this.showAlert = true;
          _this.alertContent = `Create new profile successfully. ID: ${newId}`;

          _this.resetForm();
        } else {
          // show errors
          _this.alertColor = "danger";
          _this.showAlert = true;
          _this.alertContent = `Create new profile failed. Code: ${resCode}. Reason: ${res?.data?.ext?.reason}`

          console.log("errors: " + JSON.stringify(res));
        }
        _this.submitted = false;
      });
    },
    addProfileClicked() {
      let formData = {
        user_name: this.userName,
        app_id: this.appId,
        // image: this.image,
        phone_number: this.phoneNumber
      };

      let extra = {};
      for (let customField of this.customFields) {
        if (customField.key) {
          extra[customField.key] = customField.value;
        }
      }

      if (Object.keys(extra).length > 0) {
        formData.extra = extra;
      }

      this.addProfile(formData);
    },
    importClicked() {
      var _this = this;
      
      if (_this.importFile == null) {
        _this.alertColor = "danger";
        _this.showAlert = true;
        _this.alertContent = `Please select valid CSV file.`;
        return;
      }

      _this.submitted = true;
      var formData = new FormData();
      formData.append('file', _this.importFile);

      AxiosBase.post('/profile/import',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).catch(function(error) {
        console.log(error);
        _this.submitted = false;
      })
      .then(function(){
        _this.alertColor = "success";
        _this.showAlert = true;
        _this.alertContent = `Import profiles successfully.`;

        _this.resetForm();

        _this.submitted = false;
      });
    },
    onAppIdsChanged(value) {
      // console.log(this.selectedApps);
    },
    onFileChanged(files, event) {
      this.importPlaceholder = files[0].name;
      this.importFile = files[0];
    },
    addCustomField() {
      this.customFieldId = this.customFieldId + 1;
      this.customFields.push({
        id: this.customFieldId,
        key: "",
        value: ""
      });
    },
    removeCustomField(fieldItem) {
      this.customFields = this.customFields.filter(function(item) {
        return item.id !== fieldItem.id;
      });
    }
  }
}
</script>
